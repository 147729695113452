import { CircularProgress } from "@mui/joy";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiService, ROUTES } from "../Services/apiService";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// import { apiService } from "../Services/apiService";

function Login() {
  // const handleExternalSubmit = () => {
  //   handleSubmit(onSubmit)(); // Call handleSubmit to trigger validation and onSubmit function
  // };
  // const [loadingState, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const sendOTP = async () => {
    setIsLoading(true);
    try {
      const res = await apiService.sendOTPLogin(ROUTES.LOGIN, {
        email: getValues("email"),
      });
      // console.log(res)
      if (res.status === 201) {
        setPasswordPage(true);
        setIsLoading(false);
        toast.success("OTP Sent Successfully");
      } else {
        console.log(res.data);
        setIsLoading(false)
        toast.error(res.data.detail);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data) => {
    console.log(data)
    setIsLoading(true);
    try {
      setIsLoading(true);
      // API LOGIC HERE
      console.log(data);

      const res = await apiService.verifyOTP(ROUTES.VERIFY_OTP, {
        email: data.email,
        otp: data.otp,
      });
      // console.log(res);
      // let res = {
      //   status: null,
      // };
      // console.log((data.email === "admin@gmail.com" && data.password === "admin123"))

      if (res.status === 201) {
        setIsLoading(false);
        localStorage.setItem("token", res.data.data);
        navigate("/home");
      } else if (res.status === 401) {
        setIsLoading(false);
        setInvalidCred(true);
        setTimeout(() => {
          setInvalidCred(false);
        }, 3000);
      }
    } catch (err) {
      // catch error from responsec
      console.log(err);
    }
  };
  console.log(errors);

  const [passwordPage, setPasswordPage] = useState(false);

  const [invalidCreds, setInvalidCred] = useState(false);

  return (
    <div className="flex flex-col h-[100vh] bg-primary">
      <p className="text-[#ffffff] text-[3rem] font-[400] ml-8 mt-2">Zanzoom</p>
      <div className="bg-primary flex-1 w-full flex flex-row justify-center items-center">
        <div className="">
          <Spin
            spinning={isLoading}
            tip="Loading..."
            size="large"
            fullscreen
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 34,
                }}
                spin
              />
            }
            // className="spinning_indicator"
          />
        </div>

        <div className="w-[35vw] h-[60vh] flex flex-col justify-center items-center">
          <p className="text-5xl text-[#ffffff] p-4 w-full text-center">
            Login
          </p>
          <div className="w-[35vw] h-[40vh] flex flex-col justify-center items-center p-4 gap-3">
            <div className="flex flex-col justify-center items-center gap-4">
              {/* <img src={Logo} alt="" className="w-[20vw] h-[10vh]" /> */}
              {/* <p className="text-[#898d93]">Please Login to Continue</p> */}
            </div>
            <form className="w-full h-full" onSubmit={passwordPage?handleSubmit(onSubmit):handleSubmit(sendOTP)}>
              <div className="flex flex-col justify-start w-[100%] gap-2">
                <label htmlFor="" className="text-[#ffffff] ">
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  className="w-[100%] py-3 rounded-md text-[#898d93] px-3"
                  placeholder="Type Your Admin Email"
                  {...register("email", { required: true, maxLength: 30 })}
                  // onFocus={(e) => (e.target.placeholder = "")}
                  // onBlur={(e) => (e.target.placeholder = "Type your Username")}
                />
              </div>
              {!passwordPage && (
                <button
                  className="w-[100%] bg-[#0475FF] p-3 rounded-md mt-8 text-white"
                  onClick={sendOTP}
                  type="button"
                >
                  <p className="text-[#ffffff] text-lg">Send OTP</p>
                  
                </button>
              )}

              {passwordPage && (
                <div className="">
                  <div className="flex flex-col justify-start w-[100%] gap-2 mt-4">
                    <label htmlFor="" className="text-[#ffffff] ">
                      OTP
                    </label>
                    <input
                      type="number"
                      id="otp"
                      className=" w-[100%] py-3 rounded-md text-[#898d93] px-3"
                      placeholder="Type your OTP"
                      {...register("otp", {
                        required: true,
                        maxLength: 20,
                      })}
                    />
                  </div>
                </div>
              )}
              {passwordPage && (
                <button
                  className="w-[100%] bg-[#0475FF] p-3 rounded-md mt-8 text-white"
                  // onClick={handleSubmit(onSubmit)}
                  // onClick={onSubmit}
                  type="submit"
                >
                  <p className="text-[#ffffff] text-lg">Login</p>
                  
                </button>
              )}
              

              {/* {passwordPage && (
              
            )} */}
            </form>
            {/* </div> */}
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default Login;