import React from "react";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

function TagChip({ status }) {
  return (
    <div className="w-full flex flex-row justify-center items-center">
      {status === "completed" && (
        <div
          className={`rounded-[20px] text-md bg-[#087817] text-white font-bold text-xs w-32 text-center py-[4px] cursor-pointer capitalize`}
        >
          Completed
        </div>
      )}
      {status === "cancelled" && (
        <div
          className={`rounded-[20px] text-md bg-[#E46D11] text-white font-bold border-[1px] w-32 text-xs text-center py-[4px] cursor-pointer capitalize`}
        >
          Cancelled
        </div>
      )}
      {status === "not_found" && (
        <div
          className={`rounded-[20px] text-md bg-[#E41111] text-white font-bold border-[1px] w-32 text-xs px-3 text-center py-[4px] cursor-pointer capitalize`}
        >
          Driver Not Found
        </div>
      )}
      {status === "idle" && (
        <div
          className={`rounded-[20px] text-md bg-[#BCC8C4] text-white font-bold border-[1px] w-32 text-xs px-3 text-center py-[4px] cursor-pointer capitalize`}
        >
          Idle
        </div>
      )}
      {status === "searching" && (
        <div
          className={`rounded-[20px] text-md bg-[#BCC8C4] text-[#000000] font-bold border-[1px] w-32 text-xs px-3 text-center py-[4px] cursor-pointer capitalize`}
        >
          Searching
        </div>
      )}
      {status === "driver_found" && (
        <div
          className={`rounded-[20px] text-md bg-[#0475FF] text-white border-[1px] font-bold w-32 text-xs px-3 text-center py-[4px] cursor-pointer capitalize`}
        >
          Driver Found
        </div>
      )}
      {status === "verified" && (
        <div
          className={`rounded-[20px] text-md bg-[#84ffd6] text-[#000000] border-[1px] font-bold w-32 text-xs px-3 text-center py-[4px] cursor-pointer capitalize`}
        >
          Ongoing
        </div>
      )}
    </div>
  );
}

export default TagChip;
