import { Box, TextField } from "@mui/material";
import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Root, Viewport, Pages, Page, CanvasLayer } from "@fileforge/pdfreader";
import { FaDownload } from "react-icons/fa6";
import { MdFileOpen } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { apiService, ROUTES } from "../Services/apiService";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function IndividualDriver({ isCollapsed }) {
  const navigate = useNavigate();
  // const location = useLocation();
  // const { userData } = location.state;
  // console.log(userData);
  const { contact_id } = useParams();
  console.log(contact_id)


  // let fileURL =
  //   "http://localhost:8000/api/v1/admin/store/get/{contact_id}/{filename}";

  const downloadPdf = async (filename) => {
    try {
      // const response = await axios.get(`http://localhost:8000/api/v1/admin/store/get/${userData.email}/${filename}`, {
      //   responseType: "blob",
      //   headers: apiService.getHeaders(),
      // });
      // console.log(response.data)
      // // Create a Blob from the response data
      // const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // // Create a temporary URL for the Blob
      // const url = window.URL.createObjectURL(pdfBlob);

      // // Create a temporary <a> element to trigger the download
      // const tempLink = document.createElement("a");
      // tempLink.href = url;
      // tempLink.setAttribute("download", `${userData.email}_${filename}.pdf`); // Set the desired filename for the downloaded file

      // // Append the <a> element to the body and click it to trigger the download
      // document.body.appendChild(tempLink);
      // tempLink.click();

      // // Clean up the temporary elements and URL
      // document.body.removeChild(tempLink);
      // window.URL.revokeObjectURL(url);
      const url = `${process.env.REACT_APP_API_PREFIX}/admin/store/get/${contact_id}/${filename}`;

      const headers = {
        token: `Bearer ${localStorage.getItem("token")}`, // Add your headers here
        "Content-Type": "application/pdf", // Depending on the file type
      };

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.blob())
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          console.log(downloadUrl)
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = filename; // Change the file name as needed
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => console.error("Error downloading file:", error));
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const veiwPdf = async (filename) => {
    try {
      const url = `${process.env.REACT_APP_API_PREFIX}/admin/store/get/${contact_id}/${filename}`;
  
      const headers = {
        token: `Bearer ${localStorage.getItem("token")}`, // Replace with your actual token
        "Content-Type": "application/pdf",
      };
  
      // Fetch the PDF file with headers
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
  
      // Convert the response into a Blob
      const pdfBlob = await response.blob();
  
      // Create a URL from the Blob
      const downloadUrl = window.URL.createObjectURL(pdfBlob);
      console.log(downloadUrl)
  
      // Return the created Blob URL for displaying the PDF
      // return downloadUrl;

      setDocumentUrl(downloadUrl)
  
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const approveApplication = async() => {
    let endpoint = ROUTES.APPROVE_APPLICATION.replace(
      "{contact_id}",
      contact_id
    );
    const res = await apiService.post(endpoint);
    console.log(res);
    if(res.status === 201){
      toast.success("Application Approved Successfully");
      navigate('/new-drivers')
    }
    else if(res.status === 401){
      navigate('/login')
    }
    else{
      toast.error("Something went wrong")
    }
  }

  const rejectApplication = async() => {
    let endpoint = ROUTES.REJECT_APPLICATION.replace(
      "{contact_id}",
      contact_id
    );
    const res = await apiService.post(endpoint);
    console.log(res);
    if(res.status === 206){
      toast.success("Application Rejected Successfully");
      navigate('/new-drivers')
    }
    else if(res.status === 401){
      navigate('/login')
    }
    else{
      toast.error("Something went wrong")
    }
  }
  

  const getFileURL = (contact_id, filename) => {
    return (
      "http://localhost:8000/api/v1/admin/store/get/" +
      contact_id +
      "/" +
      filename
    );
  };

  useEffect(() => {
    const getData = async () => {
      const getAlltransports = await apiService.get(ROUTES.GET_ALL_TRANSPORTS);
      let transports = getAlltransports.data.data;
      console.log(transports)
      let endpoint = ROUTES.GET_SINGLE_DRIVER_DETAILS.replace(
        "{contact_id}",
        contact_id
      );
      const res = await apiService.get(endpoint);
      if(res.status === 401){
        navigate('/login')
      }
      console.log(res);
      res.data.data.map((item)=>{
        if(item.key === 'vehicle_type'){
          transports?.map((transports)=>{
            if(transports.id === item.value){
              item.value = transports.transport_type
            }
          })
        }
      })
      setData(res.data.data);
    };
    getData();
  }, []);
  const [documentUrl, setDocumentUrl] = useState("");
  const TABLE_COLUMNS = [
    {
      title: "Field",
      dataIndex: "key",
      onFilter: (value, record) => record.account_id.includes(value),
      sorter: (a, b) => {
        return a.account_id.localeCompare(b.account_id);
      },
      width: "20%",
      align: "center",
      render: (_, { key }) => (
        <>
          <div className="w-full h-full flex flex-row items-start justify-start">
            <p>{key}</p>
          </div>
        </>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      width: "20%",
      align: "center",
      render: (_, { value }) => (
        <>
          <div className="w-full h-full flex flex-row items-start justify-end">
            <p>{value}</p>
          </div>
        </>
      ),
    },
  ];
  const [data, setData] = useState([]);
  const staticFields = [
    "firstname",
    "lastname",
    "email",
    "primary_mobile",
    "secodary_mobile",
    "address_line1",
    "address_line2",
    "vehicle_type",
    "vehicle_name",
    "vehicle_number",
    "city",
  ];

  // useEffect(() => {
  //   const getData = async () => {
  //     let endpoint = ROUTES.GET_SINGLE_DRIVER_DETAILS.replace(
  //       "{contact_id}",
  //       navigateData.email
  //     );
  //     const res = await apiService.get(endpoint);
  //     // console.log(res.data)
  //     setData(res.data.data);
  //     // setRegistrations(res.data.data)
  //   };
  //   getData();
  // }, []);

  return (
    <div
      className={`flex  h-[100vh] flex-col w-full overflow-auto relative ${
        isCollapsed ? "w-[95vw]" : "w-[75vw]"
      }`}
    >
      <div className="h-[5vh] w-full  mt-4 flex flex-row justify-between items-center px-4">
        <p className="text-3xl text-[#ffffff] ml-4">
          Driver Application Details
        </p>
      </div>
      <div className="w-full h-[90vh] flex flex-row justify-center items-center">
        <div className="w-[60%] h-full mt-12 overflow-auto">
          <Box
            // component="form"
            sx={{ "& .MuiTextField-root": { m: 1, width: "auto" } }}
            noValidate
            autoComplete="off"
          >
            <p className="text-[#ffffff] ml-6 mb-6">Personal Details</p>
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 flex-row justify-between items-center gap-4  px-4">
                {data.map((item, index) => {
                  if (!item.value.includes(".pdf")) {
                    return (
                      <TextField
                        id="outlined-read-only-input"
                        label={item.key}
                        InputProps={{
                          readOnly: true,
                        }}
                        defaultValue={item.value}
                        className="text-[#ffffff]"
                      />
                    );
                  }
                })}
              </div>
              <div className="grid grid-cols-1 justify-items-start  items-center w-[80%] gap-14 px-6">
                {data.map((item, index) => {
                  if (item.value.includes(".pdf"))
                    return (
                      <div className="flex flex-col w-[80%] text-[#ffffff]">
                        <button
                          type="button"
                          className="w-full border-2 border-[#6D71A3] p-1 rounded-md"
                          // onClick={handleInsuranceClick}
                        >
                          <div className="flex flex-row justify-start items-center gap-2">
                            <img
                              src={"/rcBook.svg"}
                              alt=""
                              className="w-10 h-10"
                            />
                            <div className="flex flex-row gap-2 justify-between items-center w-full mr-2">
                              <div className="flex flex-col justify-start items-start text-white">
                                <p className="font-[500] text-[1rem] capitalize">
                                  {item.key}
                                </p>
                                <p className="text-[0.8rem]"></p>
                              </div>
                              <div className="flex flex-row gap-4">
                                <Tooltip
                                  title="Download Document"
                                  className="bg-white text-black"
                                >
                                  <div className="bg-[#ffffff] rounded-[50%] flex justify-center items-center w-10 h-10 p-2" onClick={() => downloadPdf(item.value)}>
                                    <p >
                                      <FaDownload
                                        fontSize={"1.25rem"}
                                        fill="#000000"
                                      />
                                    </p>
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title="View Document"
                                  className="bg-white text-black"
                                >
                                  <div
                                    className="bg-[#ffffff] rounded-[50%] flex justify-center items-center w-10 h-10 p-2"
                                    onClick={() =>
                                      veiwPdf(item.value)
                                    }
                                  >
                                    <MdFileOpen
                                      fontSize={"1.25rem"}
                                      fill="#000000"
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </button>
                        <input
                          type="file"
                          // ref={insurance}
                          // onChange={(e) => handleFileChange("insurance", e)}
                          style={{ display: "none" }}
                          placeholder="Address line 1"
                          className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                        />
                      </div>
                    );
                })}
              </div>
              {/* <div className="flex flex-row justify-between items-center w-full gap-2">
                <div className="flex flex-col w-full">
                  <button
                    className="w-full border-2 border-[#6D71A3] p-1 rounded-md"
                    // onClick={handleDrivingLicenseClick}
                    type="button"
                  >
                    <div className="flex flex-row justify-start items-center gap-2">
                      <img
                        src={"/driving_license.svg"}
                        alt=""
                        className="w-10 h-10"
                      />
                      <div className="flex flex-row gap-2 justify-between items-center w-full mr-8">
                        <div className="flex flex-col justify-start items-start text-white">
                          <p className="font-[500] text-[1rem] text-[#ffffff]">
                            Driver License
                          </p>
                          <p className="text-[0.8rem] text-[#ffffff]">
                            Upload Driving License
                          </p>
                        </div>
                        <p className="text-white">
                          {'driver_license.pdf'}
                        </p>
                      </div>
                    </div>
                  </button>
                  <input
                    type="file"
                    // ref={drivingLicense} 
                    style={{ display: "none" }}
                    // onChange={(e) => handleFileChange("driving_license", e)}
                    placeholder="Address line 1"
                    className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                  />
                </div>
              </div> */}
              <div className="flex flex-row justify-end items-center gap-4 mt-8 mr-4">
                <button className="bg-[#1DC97E] text-[#ffffff] px-4 py-2 rounded-[10px]" onClick={approveApplication}>
                  Approve Application
                </button>
                <button className="bg-[#ff3636] text-[#ffffff] px-4 py-2 rounded-[10px]" onClick={rejectApplication}>
                  Reject Application
                </button>
              </div>
            </div>
          </Box>
        </div>
        <div className="w-[40%] h-full">
          <Root
            className="bg-gray-100 border-2 border-[#ffffff] rounded-md overflow-hidden w-[100%] relative h-full"
            fileURL={documentUrl}
            loader={
              <div className="p-4 w-full flex flex-row justify-center items-center h-full text-[#ffffff]">
                View Any Document to show here...
              </div>
            }
          >
            <Viewport className="p-4 h-full">
              <Pages>
                <Page className="my-4">
                  <CanvasLayer />
                </Page>
              </Pages>
            </Viewport>
          </Root>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default IndividualDriver;
