import { SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { map, get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { TbMessageCancel } from "react-icons/tb";
import { GoFileSubmodule } from "react-icons/go";
import { apiService, ROUTES } from "../Services/apiService";

function NewDrivers({ isCollapsed }) {
  //   NOTE: CHANGE DATA WHEREVER DATA IS HERE
  const messageRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedData,setClickedData] = useState();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async() => {
    let endpoint = ROUTES.SEND_MESSAGE_APPLICATION.replace('{contact_id}',clickedData.email)
    const res = await apiService.post(endpoint,{
      "message":messageRef.current.value
    })
    console.log(res)
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [registrations, setRegistrations] = useState();
  const navigate = useNavigate();
  const TABLE_COLUMNS = [
    {
      title: "Driver Name",
      dataIndex: "first_name",
      onFilter: (value, record) => record.account_id.includes(value),
      sorter: (a, b) => {
        return a.account_id.localeCompare(b.account_id);
      },
      width: "20%",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
      align: "center",
      render: (_, { email }) => (
        <>
          <div className="w-full h-full flex flex-row items-center justify-center">
            <p>{email}</p>
          </div>
        </>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "primary_mobile",
      width: "20%",
      align: "center",
      render: (_, { primary_mobile }) => (
        <>
          <div className="w-full h-full flex flex-row items-center justify-center">
            <p>{primary_mobile}</p>
          </div>
        </>
      ),
    },
    {
      title: "Application Status",
      dataIndex: "status",
      width: "20%",
      align: "center",
      render: (_, { status }) => (
        <>
          <div className={`w-auto h-full flex flex-row items-center justify-center`}>
            <p>{status}</p>
          </div>
        </>
      ),
    },
    {
      title: "Settings",
      width: "20%",
      align: "center",
      render: (_, { status }) => (
        <>
          <div className="flex flex-row w-full justify-center gap-4 items-center">
            <Tooltip title="Go to Documents" className="bg-white text-black">
              {status !== "approved" && <div
                className="w-[30px] h-[30px] rounded-[50%] bg-[#ffffff] border-2 border-black flex justify-center items-center"
                onClick={() => {
                  navigate(`/driver/${_.email}`,{
                    state:{userData: _}
                  });
                  // navigate(`/camera-view/${_.camId}`, {
                  //   state: { data: _ },
                  // });
                }}
              >
                <GoFileSubmodule
                  className="w-[15px] h-[15px]"
                  color="#000000"
                />
              </div>}
            </Tooltip>
            <Tooltip
              title="Cancel with Message"
              className="bg-white text-black"
            >
              <div
                className="w-[30px] h-[30px] rounded-[50%] bg-[#ffffff] border-2 border-[#000000] flex justify-center items-center"
                onClick={() => {setIsModalOpen(true);setClickedData(_)}}
              >
                <TbMessageCancel
                  className="w-[15px] h-[15px]"
                  color="#000000"
                />
              </div>
            </Tooltip>
          </div>
        </>
      ),
      // width:400,
    },
  ];

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  //   const onSearch = (e) => {
  //     const reg = new RegExp(e.target.value, "gi");
  //     const filteredData = map(data, (record) => {
  //       const nameMatch = get(record, "account_id").match(reg);
  //       if (!nameMatch) {
  //         return null;
  //       }
  //       return record;
  //     }).filter((record) => !!record);

  //     setSearchText(e.target.value);
  //     setFiltered(!!e.target.value);
  //     setDataState(e.target.value ? filteredData : data);
  //   };

  // const [registrations,setRegistrations] = useState();

  useEffect(() => {
    const getData = async () => {
      var endpoint = ROUTES.GET_ALL_NEW_REGISTRATIONS;
      const res = await apiService.get(endpoint);
      console.log(res)
      if(res.status === 401){
        navigate('/login')
      }
      setRegistrations(res.data.data)
    };
    getData() 
  }, []);

  return (
    <div
      className={`flex  h-[100vh] flex-col w-full overflow-auto relative ${
        isCollapsed ? "w-[95vw]" : "w-[75vw]"
      }`}
    >
      <div className="h-[5vh] w-full mt-4 flex flex-row justify-between items-center px-4">
        <p className="text-3xl text-[#ffffff] ml-4">Driver Applications</p>
        <div className="">
          {/* <Input
            size="large"
            prefix={<SearchOutlined />}
            // suffix={searchText ? <Icon type="close-circle" onClick={emitEmpty} /> : null}
            // onChange={onSearch}
            placeholder="Search Drivers"
            value={searchText}
            // onPressEnter={onSearch}
            ref={inputRef} // Assign the ref to the search input field
          /> */}
        </div>
      </div>
      <div className="flex flex-row h-[30%] w-[100%] rounded-md mt-4 p-4 border-black">
        <Table
          className="font-[inter] w-full"
          rowClassName={(record, index) =>
            index % 2 === 0
              ? "table-row-light highlight-bottom-border"
              : "table-row-dark highlight-top-border"
          }
          locale={{
            emptyText: (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  color: "white"
                }}
              >
                No Data Available
              </div>
            ),
          }}
          columns={TABLE_COLUMNS}
          bordered={true}
          dataSource={registrations}
          //   onChange={handleChange}
          pagination={false}
        />
      </div>
      <Modal title="Reject Application" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <textarea className="w-full border-2 border-[#ffffff] text-[#ffffff] focus:outline-none rounded-md bg-primary px-2 py-2" type="" ref={messageRef} placeholder="Type a Reason"/>
      </Modal>
    </div>
  );
}

export default NewDrivers;
