import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Sidenav from "./Components/Sidebar";
import NewDrivers from "./Pages/NewDrivers";
import InidividualDriver from "./Pages/IndividualDriver";
import AllDrivers from "./Pages/AllDrivers";
import { apiService } from "./Services/apiService";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/*" element={<Main />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const Main = () => {
  const navigate = useNavigate();
  const [isCollapsed,setIsCollapsed] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);
  useEffect(() => {
    checkUser();
  }, []);

  const toggleCollapsed = () =>[
    setIsCollapsed(!isCollapsed)
  ]
  // const checkUser = async () => {
  //   const isLoggedIn = await apiService.isLoggedIn();
  //   console.log(isLoggedIn);
  //   if (!isLoggedIn) {
  //     navigate("/login");
  //   }
  //   setIsUserLoggedIn(true)
  // };
  const checkUser = () => {
    setIsUserLoggedIn(true);
  };
  return (
    <>
      <div className="homepages w-full">
        <Sidenav isCollapsed={isCollapsed} toggleCollapsed={toggleCollapsed} />
        <Routes>
          
          <Route path="/home" element={<Home isCollapsed={isCollapsed}/>} />
          <Route path="/new-drivers" element={<NewDrivers isCollapsed={isCollapsed}/>} />
          <Route path="/all-drivers" element={<AllDrivers isCollapsed={isCollapsed}/>} />
          <Route path="/driver/:contact_id" element={<InidividualDriver isCollapsed={isCollapsed}/>} />
        </Routes>
      </div>
    </>
  );
};

export default App;
